import React, {useEffect, useMemo, useState} from "react";
import {
	Text,
	View,
	Modal, ScrollView, ImageBackground, Image, ActivityIndicator, Button, TextInput, StyleSheet
} from "react-native";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import API from "../../helpers/Axios";
import {TouchableOpacity} from "react-native-web";
import moment from 'moment';
import EditStudioScreen from '../../screens/modals/EditStudioScreen';
import {Popover} from "react-tiny-popover";
import AppStyles from "../../AppStyles";
import StudioStatus from "../../components/StudioStatus";
import UserStatus from "../../components/UserStatus";
import UserDetailsScreen from "../modals/UserDetailsScreen";

function ClientsScreen ({navigation, route}) {
	const [tabIndex, setTabIndex] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isDisableLoading, setIsDisableLoading] = useState(false);
	const [studios, setStudios] = useState([]);
	const [studiosData, setStudiosData] = useState(null);
	const [isStudiosLoading, setIsStudiosLoading] = useState(true);

	const [users, setUsers] = useState([]);
	const [usersData, setUsersData] = useState(null);
	const [isUsersLoading, setUsersIsLoading] = useState(true);

	const [currentStudio, setCurrentStudio] = useState(null);
	const [menuDisplay, setMenuDisplay] = useState( false);

	const [isVisible, setIsVisible] = useState(false);
	const [isPaymentVisible, setIsPaymentVisible] = useState(false);
	const [isImageEditorVisible, setIsImageEditorVisible] = useState(false);
	const [isDisableItemVisible, setIsDisableItemVisible] = useState(false);
	const [isActive, setIsActive] = useState(null);
	const [userDetailsVisible, setUserDetailsVisible] = useState(false);
	const [isDeleteLoading, setIsDeleteLoading] = useState(false);
	const [currentUser, setCurrentUser] = useState(null);
	const [term, setTerm] = useState('');

	useEffect(() => {
		if(currentStudio) {
			setIsActive(currentStudio.active);
		}
	}, [currentStudio]);

	const getUsers = (url = `admin/users`) => {
		setUsersIsLoading(true);
		API.post(url, {
			term: term
		})
			.then(res => {
				setUsers(res.data.data);
				setUsersData(res.data);
				setUsersIsLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	const getStudios = (url = `admin/studios`) => {
		API.post(url, {
			term: term
		}).then(res => {
				setStudiosData(res.data);
				setIsStudiosLoading(false);
			}).catch (error => {
			console.log(error);
		});
	}

	useEffect(() => {
		getUsers();
		getStudios();
	}, []);

	const onSearchSubmit = () => {
		if(tabIndex === 0) {
			getUsers();
		} else {
			getStudios();
		}
	}

	return (
		<View
			style={{
				flex: 1
			}}
		>
			<Modal onRequestClose={() => setUserDetailsVisible(false)} transparent visible={userDetailsVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						minWidth: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<UserDetailsScreen
						setIsVisible={setUserDetailsVisible}
						item={currentUser}
						setCurrentUser={setCurrentUser}
						getUsers={getUsers}
					/>
				</View>
			</Modal>
			<Modal onRequestClose={() => setIsVisible(false)} transparent visible={isVisible}>
				<View
					style={{
						backgroundColor: 'white',
						borderColor: '#eee',
						borderRadius: 8,
						borderWidth: 1,
						margin: 'auto',
						width: 780,
						shadowColor: "#000",
						shadowOffset: {
							width: 0,
							height: 2,
						},
						shadowOpacity: 0.05,
						shadowRadius: 3.84,
						elevation: 5,
					}}
				>
					<EditStudioScreen
						setIsVisible={setIsVisible}
						item={currentStudio}
						setCurrentStudio={setCurrentStudio}
						getStudios={getStudios}
					/>
				</View>
			</Modal>
			<ScrollView
				style={{
					flex: 1,
					padding: 40
				}}
			>
				<Text
					style={{
						fontSize: 48,
						fontWeight: '600'
					}}
				>Client database</Text>
				<Text
					style={{
						marginTop: 16,
						fontSize: 20,
						color: '#595959'
					}}
				>Here you have an overview of the users and studios of the platform!</Text>

				<View
					style={{
						marginVertical: 32,
						flexDirection: 'row'
					}}
				>
					<TouchableOpacity
						onPress={() => {
							setTabIndex(0)
						}}
						style={{
							width: 364,
							flexDirection: 'row',
							borderWidth: 2,
							borderColor: tabIndex === 0 ? '#6EB28B' : 'white',
							paddingHorizontal: 16,
							borderRadius: 8,
							backgroundColor: 'white',
							marginRight: 24,
							overflow: 'hidden',
							alignItems: 'center',
							height: 100,
							justifyContent: 'space-between',
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
						}}
					>
						<svg width={55} height={36} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M47.746 12.222a4.72 4.72 0 1 1-6.676 6.675 4.72 4.72 0 0 1 6.676-6.675M32.37 3.796a7.268 7.268 0 1 1-10.28 10.279 7.268 7.268 0 0 1 10.28-10.28M13.39 12.222a4.72 4.72 0 1 1-6.676 6.675 4.72 4.72 0 0 1 6.676-6.675M52.896 34.333v-2.557a5.832 5.832 0 0 0-5.833-5.833h-1.87M1.563 34.333v-2.557a5.832 5.832 0 0 1 5.833-5.833h1.87M39.687 34.333v-3.735a8.167 8.167 0 0 0-8.166-8.167h-8.585a8.167 8.167 0 0 0-8.166 8.167v3.735"
								stroke={tabIndex === 0 ? '#096730' : '#595959'}
								strokeWidth={3}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<Text
							style={{
								fontWeight: '600',
								color: tabIndex === 0 ? '#096730' : '#595959',
								fontSize: 18
							}}
						>{usersData && usersData.total} Users</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => {
							setTabIndex(1)
						}}
						style={{
							width: 364,
							flexDirection: 'row',
							borderWidth: 2,
							borderColor: tabIndex === 1 ? '#6EB28B' : 'white',
							paddingHorizontal: 16,
							borderRadius: 8,
							backgroundColor: 'white',
							marginRight: 24,
							overflow: 'hidden',
							alignItems: 'center',
							height: 100,
							justifyContent: 'space-between',
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
						}}
					>
						<svg width={57} height={56} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M32.77 18.663V9.326a2.334 2.334 0 0 1 3.153-2.185l11.672 4.377a2.334 2.334 0 0 1 1.515 2.187v35.304"
								stroke={tabIndex === 1 ? '#096730' : '#595959'}
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								clipRule="evenodd"
								d="M21.565 18.663h13.072a2.801 2.801 0 0 1 2.801 2.8V49.01H18.764V21.464a2.801 2.801 0 0 1 2.8-2.801Z"
								stroke={tabIndex === 1 ? '#096730' : '#595959'}
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M25.767 18.663v-4.669a2.334 2.334 0 0 0-2.335-2.334h-9.337a2.334 2.334 0 0 0-2.334 2.334v11.672M18.764 6.991v4.669M51.444 49.009H4.758M24.6 31.968h7.002M24.6 25.666h7.002M24.6 38.271h7.002"
								stroke={tabIndex === 1 ? '#096730' : '#595959'}
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M7.092 49.009V28a2.334 2.334 0 0 1 2.335-2.334h9.337"
								stroke="#6D6E6F"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						<Text
							style={{
								fontWeight: '600',
								color: tabIndex === 1 ? '#096730' : '#595959',
								fontSize: 18
							}}
						>{studiosData && studiosData.total} Studios Users</Text>
					</TouchableOpacity>
				</View>
				<View
					style={{
						marginBottom: 32,
						flexDirection: 'row',
						justifyContent: 'space-between'
					}}
				>
					<View
						style={{
							flexDirection: 'row',
							alignItems: 'center',
							backgroundColor: 'white',
							flex: 1,
							boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.08)',
							paddingVertical: 16,
							paddingHorizontal: 16,
							borderRadius: 8,
							marginRight: 16
						}}
					>
						<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M11.837 4.999a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z"
								fill="#8692A6"
							/>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M16.78 16.942a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z"
								fill="#8692A6"
							/>
						</svg>
						<TextInput
							placeholder={'Search for email, phone number'}
							style={{
								flex: 1,
								outline: 0,
								paddingHorizontal: 16,
								fontSize: 16
							}}
							value={term}
							onChangeText={(text) => setTerm(text)}
							onSubmitEditing={(e) => {
								onSearchSubmit();
								e.preventDefault();
							}}
						/>
					</View>
					<Popover
						isOpen={menuDisplay}
						positions={['bottom', 'right']}
						align={'end'}
						containerStyle={{
							zIndex: 5,
						}}
						padding={10}
						reposition={false}
						onClickOutside={() => setMenuDisplay(false)}
						content={({ position, nudgedLeft, nudgedTop }) => (
							<View
								style={{
									borderRadius: 8,
									width: 240,
									backgroundColor: 'white',
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 3.84,
									elevation: 5,
									paddingHorizontal: 16
								}}
							>

							</View>
						)}
					>
						<TouchableOpacity
							onPress={() => {
								setMenuDisplay(!menuDisplay)
							}}
							style={{
								backgroundColor: 'white',
								padding: 16,
								borderRadius: 8,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: 240
							}}
						>
							<View>
								<Text
									style={{
										fontWeight: '600',
										color: '#096730'
									}}
								>Sort by:</Text>
								<Text
									style={{
										fontWeight: '600',
										marginTop: 4
									}}
								>Date</Text>
							</View>
							<svg
								width={16} height={16}
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 492 492"
								style={{
									enableBackground: "new 0 0 492 492",
								}}
								xmlSpace="preserve"
							>
								<path d="M246.1 293.4 62 109.3c-5.1-5.1-11.8-7.9-19-7.9-7.2 0-14 2.8-19 7.9L7.9 125.5c-5.1 5.1-7.9 11.8-7.9 19 0 7.2 2.8 14 7.9 19L227 382.7c5.1 5.1 11.9 7.9 19.1 7.8 7.2 0 14-2.8 19.1-7.8l218.9-218.9c5.1-5.1 7.9-11.8 7.9-19 0-7.2-2.8-14-7.9-19L468 109.5c-10.5-10.5-27.6-10.5-38.1 0L246.1 293.4z" />
							</svg>
						</TouchableOpacity>
					</Popover>
				</View>
				{tabIndex === 0 && (
					<View
						style={{
							backgroundColor: 'white',
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.05,
							shadowRadius: 3.84,
							elevation: 5,
							borderRadius: 8
						}}
					>
						<View
							style={{
							}}
						>
							<View
								style={{
									borderBottomWidth: 2,
									borderColor: '#096730',
									alignSelf: 'flex-start',
									paddingHorizontal: 16
								}}
							>
								<Text
									style={{
										fontWeight: '600',
										fontSize: 16,
										color: '#096730',
										lineHeight: 40
									}}
								>All Users</Text>
							</View>
						</View>
						<table
							style={{
								flex: 1,
								width: '100%',
								borderCollapse: 'collapse'
							}}
						>
							<tr
								style={{
									backgroundColor: '#F8F8F8',
									height: 48
								}}
							>
								<th></th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Name</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Phone Number</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Date Added</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
									<Text
										style={styles.tableHeader}
									>Status</Text>
								</th>
								<th
									style={{
										textAlign: 'left',
										paddingLeft: 16,
										paddingRight: 16
									}}
								>
								</th>
							</tr>
							{usersData && usersData.data.map((item, index) => (
								<tr
									key={index}
									style={{
										height: 64,
										cursor: 'pointer'
									}}
									onClick={() => {
										setCurrentUser(item);
										setUserDetailsVisible(true);
									}}
								>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Image
											style={{
												borderRadius: 16,
												marginRight: 16,
												width: 32,
												height: 32,
											}}
											source={{
												uri: item.artwork_url
											}}
										/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<View>
											<Text>{item.name}</Text>
											<Text
												style={{
													color: '#909090',
													marginTop: 4,
													fontWeight: '600'
												}}
											>{item.email}</Text>
										</View>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{AppStyles.utils.formatPhoneNumber(item?.phone_verification?.phone_number)}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<UserStatus status={item.banned}/>
									</td>
									<td
										style={{
											borderBottom: '1px solid',
											borderBottomColor: '#E4E1E0',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												color: '#595959'
											}}
										>View More</Text>
									</td>
								</tr>
							))}
						</table>
						{usersData && (
							<View
								style={{
									backgroundColor: '#F8F8F8',
									height: 48,
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'flex-end',
									paddingHorizontal: 24
								}}
							>
								<View
									style={{
										marginRight: 40,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											marginRight: 8
										}}
									>Rows per page: {usersData.per_page}</Text>
									<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
									</svg>
								</View>
								<Text
									style={{
										fontWeight: '600'
									}}
								>{usersData.from}- {usersData.to} </Text>
								<View
									style={{
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'space-between',
										width: 80,
										marginLeft: 40
									}}
								>
									<TouchableOpacity
										onPress={() => {
											if(!usersData.prev_page_url) {
												return;
											}
											setUsersIsLoading(true);
											getUsers(usersData.prev_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
												fill={usersData.prev_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
									{isUsersLoading && (
										<ActivityIndicator color={'#096730'} />
									)}
									<TouchableOpacity
										onPress={() => {
											if(!usersData.next_page_url) {
												return;
											}
											setUsersIsLoading(true);
											getUsers(usersData.next_page_url);
										}}
									>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
												fill={usersData.next_page_url ? '#6D6E6F' : '#ccc'}
											/>
										</svg>
									</TouchableOpacity>
								</View>
							</View>
						)}
					</View>
				)}
				{tabIndex === 1 && (
					<View
					>
						<View
							style={{
								backgroundColor: 'white',
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.05,
								shadowRadius: 3.84,
								elevation: 5,
								borderRadius: 8
							}}
						>
							<View
								style={{
								}}
							>
								<View
									style={{
										borderBottomWidth: 2,
										borderColor: '#096730',
										alignSelf: 'flex-start',
										paddingHorizontal: 16
									}}
								>
									<Text
										style={{
											fontWeight: '600',
											fontSize: 16,
											color: '#096730',
											lineHeight: 40
										}}
									>All Studios</Text>
								</View>
							</View>
							<table
								style={{
									flex: 1,
									width: '100%',
									borderCollapse: 'collapse'
								}}
							>
								<tr
									style={{
										backgroundColor: '#F8F8F8',
										height: 48
									}}
								>
									<th></th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Name</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Contact Number</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Date Added</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
										<Text
											style={styles.tableHeader}
										>Status</Text>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
									<th
										style={{
											textAlign: 'left',
											paddingLeft: 16,
											paddingRight: 16
										}}
									>
									</th>
								</tr>
								{studiosData && studiosData.data.map((item, index) => (
									<tr
										key={index}
										style={{
											height: 64,
											cursor: 'pointer'
										}}
										onClick={() => {
											setCurrentStudio(item);
											setIsVisible(true);
										}}
									>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Image
												style={{
													borderRadius: 16,
													marginRight: 16,
													width: 32,
													height: 32,
												}}
												source={{
													uri: item.artwork_url
												}}
											/>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<View>
												<Text>{item.name}</Text>
												<Text
													style={{
														color: '#909090',
														marginTop: 4,
														fontWeight: '600'
													}}
												>{item.user && item.user.email}</Text>
											</View>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{item.user && AppStyles.utils.formatPhoneNumber(item.user?.phone_verification?.phone_number)}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text>{moment.utc(item.created_at).local().format("MMM D, YYYY")}</Text>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<StudioStatus status={item.approved}/>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
												<g clipPath="url(#a)" fill="#6D6E6F">
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M7.333 1.333A.667.667 0 0 0 6.666 2v5.588l1.702-.851a.667.667 0 0 1 .298-.07H14A.667.667 0 0 0 14.666 6V2A.667.667 0 0 0 14 1.333H7.333ZM5.919.586A2 2 0 0 1 7.333 0H14a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H8.824L6.298 9.263a.667.667 0 0 1-.965-.596V2A2 2 0 0 1 5.919.586Z"
													/>
													<path
														fillRule="evenodd"
														clipRule="evenodd"
														d="M1.252 1.92a2 2 0 0 1 1.415-.587h1a.667.667 0 1 1 0 1.334h-1A.667.667 0 0 0 2 3.333V14a.667.667 0 0 0 .667.667H10a.667.667 0 0 0 .666-.667v-4A.667.667 0 0 1 12 10v4a2 2 0 0 1-2 2H2.667a2 2 0 0 1-2-2V3.333a2 2 0 0 1 .585-1.414Z"
													/>
													<circle cx={6.667} cy={12.667} r={0.667} />
												</g>
												<defs>
													<clipPath id="a">
														<path fill="#fff" d="M0 0h16v16H0z" />
													</clipPath>
												</defs>
											</svg>
										</td>
										<td
											style={{
												borderBottom: '1px solid',
												borderBottomColor: '#E4E1E0',
												paddingLeft: 16,
												paddingRight: 16
											}}
										>
											<Text
												style={{
													fontWeight: '600',
													color: '#595959'
												}}
											>View More</Text>
										</td>
									</tr>
								))}
							</table>
							{studiosData && (
								<View
									style={{
										backgroundColor: '#F8F8F8',
										height: 48,
										flexDirection: 'row',
										alignItems: 'center',
										justifyContent: 'flex-end',
										paddingHorizontal: 24
									}}
								>
									<View
										style={{
											marginRight: 40,
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
										}}
									>
										<Text
											style={{
												fontWeight: '600',
												marginRight: 8
											}}
										>Rows per page: {studiosData.per_page}</Text>
										<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M12 14 8 9h8l-4 5Z" fill="#6D6E6F" />
										</svg>
									</View>
									<Text
										style={{
											fontWeight: '600'
										}}
									>{studiosData.from}- {studiosData.to} </Text>
									<View
										style={{
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: 80,
											marginLeft: 40
										}}
									>
										<TouchableOpacity
											onPress={() => {
												if(!studiosData.prev_page_url) {
													return;
												}
												setIsStudiosLoading(true);
												getStudios(studiosData.prev_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M14.8 8.106c.628-.593-.367-1.533-.995-.89L9.196 11.52a.66.66 0 0 0 0 .94l4.609 4.352c.628.593 1.623-.346.995-.94l-4.085-3.857L14.8 8.106Z"
													fill={studiosData.prev_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
										{isStudiosLoading && (
											<ActivityIndicator color={'#096730'} />
										)}
										<TouchableOpacity
											onPress={() => {
												if(!studiosData.next_page_url) {
													return;
												}
												setIsStudiosLoading(true);
												getStudios(studiosData.next_page_url);
											}}
										>
											<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
												<path
													d="M9.2 8.106c-.628-.593.367-1.533.995-.89l4.609 4.303a.66.66 0 0 1 0 .94l-4.609 4.352c-.628.593-1.623-.346-.995-.94l4.085-3.857L9.2 8.106Z"
													fill={studiosData.next_page_url ? '#6D6E6F' : '#ccc'}
												/>
											</svg>
										</TouchableOpacity>
									</View>
								</View>
							)}
						</View>
					</View>
				)}
			</ScrollView>
		</View>
	);
}

const styles = StyleSheet.create({
	tableHeader: {
		fontWeight: '600',
		textAlign: 'left',
		alignSelf: 'flex-start'
	},
	tableCell: {
		borderBottom: '1px solid',
		borderBottomColor: 'gray',
		paddingLeft: 16,
		paddingRight: 16
	},
});

ClientsScreen.propTypes = {
	navigation: PropTypes.object,
};

export default connect(({auth}) => ({auth}))(ClientsScreen);
